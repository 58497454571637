<template>
  <div class="v-stack h-stretch gap-3">
    <a class="heading-title-2">Projects</a>
    <div class="card light mn">
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <div class="pane-horizontal gap-3">
            <div class="v-stack h-stretch v-start gap-3 card py-5">
              <a>Crew</a>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Pre Production:</div>
                <div class="text-left">
                  {{ format.currency(sumPreProduction) }} kč
                </div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Production:</div>
                <div class="text-left">
                  {{ format.currency(sumProduction) }} kč
                </div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Post Production:</div>
                <div class="text-left">
                  {{ format.currency(sumPostProduction) }} kč
                </div>
              </div>
            </div>
            <div class="v-stack h-stretch v-start gap-3 card py-5">
              <a>Other</a>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Gear:</div>
                <div class="text-left">{{ format.currency(sumGear) }} kč</div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Production Fee:</div>
                <div class="text-left">
                  {{ format.currency(sumProductionFee) }} kč
                </div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Travel:</div>
                <div class="text-left">{{ format.currency(sumTravel) }} kč</div>
              </div>
            </div>
          </div>
          <div class="v-stack h-stretch v-start gap-3 card py-5">
            <a>Sum</a>
            <div class="pane-horizontal gap-3">
              <div class="text-right">Crew:</div>
              <div class="text-left">{{ format.currency(sumCrew) }} kč</div>
            </div>
            <div class="pane-horizontal gap-3">
              <div class="text-right">Other:</div>
              <div class="text-left">{{ format.currency(sumOther) }} kč</div>
            </div>
            <div class="separator"></div>
            <div class="pane-horizontal gap-3" style="font-weight: bold">
              <div class="text-right">Total:</div>
              <div class="text-left">{{ format.currency(sumTotal) }} kč</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Table
      :items="filteredProjects"
      :defaultColumn="2"
      :defaultAscending="true"
      :limit="10"
      :height="360"
      :pageable="true"
      v-on:rowSelected="$router.push('/projects/detail/' + $event._id)"
      css="60px 60px 1fr 80px 80px 200px"
      :columns="[
        {
          name: 'State',
          sort: 'alphabet',
          path: ['state'],
        },
        {
          name: 'Icon',
          sort: 'alphabet',
          path: ['icon'],
        },
        {
          name: 'Project',
          sort: 'alphabet',
          path: ['name'],
        },
        {
          name: 'Created',
          sort: 'date',
          path: ['createdDate'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Deadline',
          sort: 'date',
          path: ['deadline'],
          formatType: 'date',
          format: 'DD.MM.YYYY',
        },
        {
          name: 'Budget',
          sort: 'numeric',
          path: ['budget'],
          format: 'czk',
          formatType: 'currency',
        },
      ]"
    >
    </Table>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import utils from "@/utils.js";

export default {
  data() {
    return {
      sumGear: 0,
      sumProductionFee: 0,
      sumTravel: 0,
      sumPreProduction: 0,
      sumProduction: 0,
      sumPostProduction: 0,
      format: utils.format,
    };
  },
  components: {
    Table,
  },
  computed: {
    sumCrew() {
      return (
        this.sumPreProduction + this.sumProduction + this.sumPostProduction
      );
    },
    sumOther() {
      return this.sumGear + this.sumProductionFee + this.sumTravel;
    },
    sumTotal() {
      return this.sumCrew + this.sumOther;
    },
  },
  props: {
    filteredProjects: {
      type: Array,
      default: () => [],
    },
    projectIncome: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    filteredProjects() {
      this.calculate();
    },
  },
  methods: {
    calculate() {
      this.sumGear = 0;
      this.sumProductionFee = 0;
      this.sumTravel = 0;
      this.sumPreProduction = 0;
      this.sumProduction = 0;
      this.sumPostProduction = 0;

      for (const project of this.filteredProjects) {
        for (const pricelist of project.selectedPricelists) {
          this.sumProductionFee += utils.pricelist.productionFee(pricelist);
          this.sumGear += utils.pricelist.discount(pricelist);

          for (const itemContainer of pricelist.items) {
            if (itemContainer.item.categoryMain == "PERSONÁL / SLUŽBY") {
              if (itemContainer.item.categorySub == "Preprodukce") {
                this.sumPreProduction +=
                  itemContainer.count * itemContainer.price;
              }
            }
            if (itemContainer.item.categoryMain == "PERSONÁL / SLUŽBY") {
              if (itemContainer.item.categorySub == "Produkce") {
                this.sumProduction += itemContainer.count * itemContainer.price;
              }
            }
            if (itemContainer.item.categoryMain == "PERSONÁL / SLUŽBY") {
              if (itemContainer.item.categorySub == "Postprodukce") {
                this.sumPostProduction +=
                  itemContainer.count * itemContainer.price;
              }
            }

            if (itemContainer.item.categoryMain == "TECHNIKA") {
              if (itemContainer.item.categorySub == "Doprava") {
                this.sumTravel += itemContainer.count * itemContainer.price;
              } else {
                this.sumGear += itemContainer.count * itemContainer.price;
              }
            }
          }
        }
      }
    },
  },
  mounted() {
    this.calculate();
  },
};
</script>

<style></style>
